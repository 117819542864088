<template>
  <div>
    <AthletesLoadRequired />

    <section class="mt-4 pt-5">
      <ValidationObserver
        ref="observer"
        v-slot="{ validate, invalid }"
        tag="form"
        @submit.prevent="handleSubmit"
      >
        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="required|ext:xlsx"
          name="Файл"
        >
          <b-field
            class="file is-primary"
            :class="{ 'has-name': !!file, 'is-danger': !valid }"
          >
            <b-upload v-model="file" class="file-label">
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload" />
                <span class="file-label">Выбрать файл</span>
              </span>
              <span v-if="file" class="file-name">
                {{ file.name }}
              </span>
            </b-upload>
          </b-field>
          <span id="error" class="has-text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <div class="mt-4">
          <b-field>
            <b-switch v-model="isPublished">
              Публиковать
              <span class="has-text-grey">(не рекомендуется!!!)</span>
            </b-switch>
          </b-field>
        </div>

        <AppFormButtons
          :save-title="saveTitle"
          :is-loading="isLoading"
          :is-show-reset="true"
          :is-show-close="false"
          :is-submit-disabled="invalid"
          @send="validate().then(handleSubmit)"
          @reset="handleReset"
          class="mt-6"
        />
      </ValidationObserver>
    </section>

    <section class="mt-4 pt-6">
      <AthletesLoadLoaded
        :athletes="this.athletes"
        :file-errors="this.fileErrors"
      />
    </section>
  </div>
</template>

<script>
import AthletesLoadRequired from "@/modules/athletes/components/AthteteLoadRequired";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AppFormButtons from "@/common/components/AppFormButtons";
import { Resource } from "@/common/const/common";
import AthletesLoadLoaded from "@/modules/athletes/components/AthletesLoadLoaded";
import { SAVE_ERROR } from "@/common/const/message";

export default {
  name: "AthletesLoad",

  components: {
    AthletesLoadLoaded,
    AppFormButtons,
    AthletesLoadRequired,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      isPublished: false,
      file: null,
      isLoading: false,
      athletes: [],
      fileErrors: {},
      saveTitle: "Загрузить",
    };
  },

  methods: {
    async handleSubmit() {
      this.isLoading = true;
      this.athletes = [];
      this.fileErrors = {};

      try {
        const formData = this.getFormData();
        const data = await this.$api[Resource.PEOPLES].post(
          formData,
          "load-athletes"
        );

        this.athletes = data?.athletes || [];
        this.fileErrors = data?.errors || {};
      } catch (e) {
        const message = e?.response?.data?.message || SAVE_ERROR;
        this.$notifier.error(message);
        this.file = null;
      } finally {
        this.isLoading = false;
      }
    },

    async handleReset() {
      this.file = null;
      this.athletes = [];
      this.fileErrors = {};
    },

    getFormData() {
      const formData = new FormData();
      const data = { isPublished: this.isPublished };

      if (this.file && this.file instanceof Blob) {
        formData.append("files.file", this.file, this.file.name);
      }
      formData.append("data", JSON.stringify(data));

      return formData;
    },
  },
};
</script>
