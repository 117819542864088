<template>
  <div>
    <div v-if="athletes.length" class="content">
      <p class="title is-size-5">Загружено</p>
      <ul>
        <li
          v-for="{ id, fullName, birthDate, category_sport } in athletes"
          :key="id"
        >
          <router-link
            :to="{ name: 'AthleteShow', params: { id } }"
            target="_blank"
          >
            {{ fullName }}, {{ $dayjs(birthDate).format(format) }},
            {{ category_sport.Title }}
          </router-link>
        </li>
      </ul>
    </div>

    <div v-if="isShowErrors" class="content">
      <p class="title is-size-5">Ошибки</p>

      <ul>
        <li v-for="(value, name) in fileErrors" :key="getKey(name)">
          <div style="display: flex">
            <div style="flex: 4 3 0">{{ name }}</div>
            <div style="flex: 3 1 0" class="has-text-danger">
              {{ printErrors(value) }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { DATE_PRINT_FORMAT } from "@/common/const/common";

export default {
  name: "AthletesLoadLoaded",

  props: {
    athletes: {
      type: Array,
      required: true,
    },
    fileErrors: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      format: DATE_PRINT_FORMAT,
      dataErrors: [],
    };
  },

  computed: {
    isShowErrors() {
      return Object.keys(this.fileErrors).length > 0;
    },
  },

  methods: {
    getKey(name) {
      return name.toLowerCase().split(" ").join("-");
    },
    printErrors(value) {
      return value?.errors.join(", ") || "";
    },
  },
};
</script>
