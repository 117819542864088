<template>
  <div>
    <p class="title is-size-4">Требования к файлу</p>

    <b-table
      :data="athletesLoadRequiredData"
      :columns="athletesLoadRequiredColumns"
      :mobile-cards="false"
      bordered
    >
    </b-table>

    <div class="content my-4">
      <p>
        Поддерживается только файлы MS Excel (.xlsx). Формат даты рождения,
        названия и порядок столбцов строго по образцу. Для удобства можно
        создавать листы по видам спорта. Не рекомендуется загружать файлы с
        числом спортсменов более 100.
      </p>
      <p class="is-flex is-align-items-center">
        <router-link
          :to="AppRoute.RANKS"
          target="_blank"
          title="Смотреть звания"
          >Список званий.&nbsp;</router-link
        >
        <router-link
          :to="AppRoute.REGIONS"
          target="_blank"
          title="Смотреть регионы"
          >Список регионов.</router-link
        >
        <b-button
          type="is-info is-light"
          icon-left="file-excel"
          @click="download"
          class="ml-4"
          title="Скачать файл"
        >
          Скачать шаблон
        </b-button>
      </p>
    </div>
  </div>
</template>

<script>
import {
  ATHLETES_TEMPLATE,
  athletesLoadRequiredColumns,
  athletesLoadRequiredData,
} from "@/modules/athletes/const";
import { AppRoute } from "@/common/const/route";

export default {
  name: "AthletesLoadRequired",

  data() {
    return {
      athletesLoadRequiredData,
      athletesLoadRequiredColumns,
      AppRoute,
    };
  },

  methods: {
    download() {
      window.location.href = ATHLETES_TEMPLATE;
    },
  },
};
</script>
